import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

if(firebase.messaging.isSupported()) {
const firebaseConfig = {
    apiKey: "AIzaSyCkQDwgMF5eOqN1-GK9hqQrZGxbd0fe-38",
    authDomain: "vwell-a780a.firebaseapp.com",
    databaseURL: "https://vwell-a780a.firebaseio.com",
    projectId: "vwell-a780a",
    storageBucket: "vwell-a780a.appspot.com",
    messagingSenderId: "840734776467",
    appId: "1:840734776467:web:8e6a34baab80b181f1c166"
  };

firebase.initializeApp(firebaseConfig)
}

export default firebase.messaging.isSupported() ? firebase.messaging() : null